define("lokaltipp-ember/pods/components/advent-calendar-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uh5EjMST",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[4,\"if\",[[25,[\"showPopup\"]]],null,{\"statements\":[[4,\"in-element\",[[25,[\"bodyDom\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[23,\"styleNamespace\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"image-area\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"content\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"page-editor\",null,[[\"value\"],[[25,[\"markup\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            Gewinnspiel ist nicht verfügbar\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"image-navigation\"],[9],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"advent\"]]],null],null],null]],[9],[7,\"img\"],[11,\"alt\",\"Schließen\"],[11,\"src\",\"/assets/menu-close.svg\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/advent-calendar-popup/template.hbs"
    }
  });

  _exports.default = _default;
});