define("lokaltipp-ember/pods/calendar/day/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B9WR/0kw",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"lt-calendar\",null,[[\"events\",\"day\",\"lokal\"],[[25,[\"model\",\"events\"]],[25,[\"model\",\"day\"]],[25,[\"model\",\"lokal\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"lokal\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"top-bar-lokal\",null,[[\"lokal\",\"isLokalPage\"],[[25,[\"model\",\"lokal\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"top-bar-article\",null,[[\"model\"],[[29,\"hash\",null,[[\"title\"],[\"Kulinarischer Kalender\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/calendar/day/index/template.hbs"
    }
  });

  _exports.default = _default;
});