define("lokaltipp-ember/pods/components/top-bar-article/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    topBarElement: Ember.computed(function () {
      return document.getElementById('top-bar-element');
    })
  });

  _exports.default = _default;
});