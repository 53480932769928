define("lokaltipp-ember/pods/components/top-bar-lokal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    pageEditorService: Ember.inject.service(),
    topBarElement: Ember.computed(function () {
      return document.getElementById('top-bar-element');
    }),
    isOpen: false,
    isPremium: Ember.computed('lokal.premiumStatusId', function () {
      var premiumStatus = Ember.get(this, 'lokal.premiumStatusId');
      return premiumStatus > 0;
    }),
    canReservieren: Ember.computed('lokal.reservationsEnabled', function () {
      return Ember.get(this, 'lokal.reservationsEnabled');
    }),
    actions: {
      toggleInfo: function toggleInfo() {
        Ember.set(this, 'isOpen', !Ember.get(this, 'isOpen'));
      }
    }
  });

  _exports.default = _default;
});