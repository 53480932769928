define("lokaltipp-ember/pods/components/page-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+JEy6yn",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"lt-page-editor-readonly\",null,[[\"columns\",\"resolver\",\"transition\",\"value\",\"model\"],[[29,\"if\",[[25,[\"columns\"]],[25,[\"columns\"]],3],null],[29,\"action\",[[24,0,[]],[25,[\"resolver\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"transition\"]]],null],[25,[\"_value\"]],[25,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/page-editor/template.hbs"
    }
  });

  _exports.default = _default;
});