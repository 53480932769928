define("lokaltipp-ember/helpers/format-as-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAsTime = formatAsTime;
  _exports.default = void 0;

  function formatAsTime(params
  /*, hash*/
  ) {
    if (!params[0]) {
      return null;
    }

    var parts = (params[0] || '').split(':');
    return "".concat(parts[0], ":").concat(parts[1]);
  }

  var _default = Ember.Helper.helper(formatAsTime);

  _exports.default = _default;
});