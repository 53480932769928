define("lokaltipp-ember/pods/components/search-result-lokal-premium/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-result'],
    pageEditorService: Ember.inject.service(),
    references: Ember.computed('item.publicId', 'item.link', 'item.title', function () {
      return [{
        id: Ember.get(this, 'item.link'),
        title: Ember.get(this, 'item.title'),
        publicId: Ember.get(this, 'item.publicId')
      }];
    }),
    transition: function transition(query, options) {
      var references = Ember.get(this, 'references');
      return Ember.get(this, 'pageEditorService').transition(query, options, references);
    },
    resolver: function resolver(type, query, options) {
      var references = Ember.get(this, 'references');
      return Ember.get(this, 'pageEditorService').resolver(type, query, options, references);
    }
  });

  _exports.default = _default;
});