define("lokaltipp-ember/pods/components/image-viewer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageEditorService: Ember.inject.service(),
    tagName: '',
    index: 0,
    img: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._imageEvent = this._imageEvent.bind(this);
      this._imageClick = this._imageClick.bind(this);
      this._images = Ember.A([]);
      var v = Ember.get(this, 'img');

      if (v) {
        Ember.set(this, 'showImageViewer', true);
        this._forceLoad = v.split('-');
        this._forceLoad = {
          src: this._forceLoad[0],
          idx: this._forceLoad[1] | 0
        };
      }
    },
    bodyDom: Ember.computed(function () {
      return document.body;
    }),
    willInsertElement: function willInsertElement() {
      document.body.addEventListener('lt-page-editor-image-tag', this._imageEvent);
      document.body.addEventListener('click', this._imageClick);
    },
    willDestroyElement: function willDestroyElement() {
      document.body.removeEventListener('lt-page-editor-image-tag', this._imageEvent);
      document.body.removeEventListener('click', this._imageClick);
    },
    _imageEvent: function _imageEvent(_ref) {
      var _this = this;

      var detail = _ref.detail;

      if (detail.added) {
        // search if same src/copyright/description
        var x = this._images.find(function (x) {
          return x.src === detail.src && x.copyright === detail.copyright && JSON.stringify(x.colors) === JSON.stringify(detail.colors) && JSON.stringify(x.description) === JSON.stringify(detail.description);
        });

        if (x) {
          // overwrite
          Ember.set(x, 'count', Ember.get(x, 'count') + 1);
          Ember.set(x, 'src', detail.src);
          Ember.set(x, 'colors', detail.colors);
          Ember.set(x, 'copyright', detail.copyright);
          Ember.set(x, 'description', detail.description);
          Ember.set(x, 'height', detail.height);
          Ember.set(x, 'width', detail.width);
          Ember.get(x, 'guid').push(detail.guid);
        } else {
          this._images.addObject({
            src: detail.src,
            count: 1,
            guid: [detail.guid],
            colors: detail.colors,
            copyright: detail.copyright,
            description: detail.description,
            height: detail.height,
            width: detail.width
          });
        }

        if (this._forceLoad && this._forceLoad.src === detail.src) {
          var r = this._images.filter(function (x) {
            return x.src === _this._forceLoad.src;
          });

          var img = r[this._forceLoad.idx];

          if (img) {
            var idx = this._images.findIndex(function (x) {
              return x === img;
            });

            Ember.set(this, 'index', idx);
            this._forceLoad = null;
          }
        }
      }

      if (detail.removed) {
        var _x = this._images.find(function (x) {
          return x.src === detail.src && x.copyright === detail.copyright && JSON.stringify(x.colors) === JSON.stringify(detail.colors) && JSON.stringify(x.description) === JSON.stringify(detail.description);
        });

        if (_x) {
          Ember.set(_x, 'count', Ember.get(_x, 'count') - 1);
          Ember.set(_x, 'guid', Ember.get(_x, 'guid').filter(function (y) {
            return Ember.get(y, 'guid') !== _x.guid;
          }));

          if (Ember.get(_x, 'count') <= 0) {
            this._images.removeObject(_x);
          }
        } else {//error
          //debugger;
        }
      }
    },
    _imageClick: function _imageClick(e) {
      // extract event path:
      var paths = [];
      var tmp = e.target;

      while (tmp) {
        paths.push(tmp);
        tmp = tmp.parentElement;
      }

      var image = paths.findIndex(function (x) {
        return x && x.classList && x.classList.contains('tagit');
      });

      if (image < 0) {
        return;
      }

      var stag = paths.findIndex(function (x) {
        return x && x.tagName == 'A';
      });

      if (stag > 0 && stag > image) {
        // don't do it !
        return;
      } // find image clicked


      var img = this._images.findIndex(function (x) {
        return x.guid.includes(paths[image].id);
      });

      if (img >= 0) {
        Ember.set(this, 'index', img);
      }

      Ember.set(this, 'showImageViewer', true);
    },
    obsImageViewer: Ember.observer('showImageViewer', 'index', function () {
      var currentImage = Ember.get(this, 'currentImage');
      var value = Ember.get(this, 'currentImage.src');

      var filteredList = this._images.filter(function (x) {
        return x.src === value;
      });

      if (filteredList.length > 1) {
        value += '-' + filteredList.findIndex(function (x) {
          return x === currentImage;
        });
      }

      if (Ember.get(this, 'showImageViewer')) {
        Ember.set(this, 'img', value);
      } else {
        Ember.set(this, 'img', null);
      }
    }),
    obsImg: Ember.observer('img', function () {
      var _this2 = this;

      if (Ember.get(this, 'showImageViewer')) {
        if (!Ember.get(this, 'img')) {
          Ember.set(this, 'showImageViewer', false);
        }
      } else {
        if (Ember.get(this, 'img')) {
          Ember.set(this, 'showImageViewer', true);
        }
      }

      if (Ember.get(this, 'showImageViewer')) {
        // load stuff:
        var v = Ember.get(this, 'img');

        if (v) {
          Ember.set(this, 'showImageViewer', true);
          this._forceLoad = v.split('-');
          this._forceLoad = {
            src: this._forceLoad[0],
            idx: this._forceLoad[1] | 0
          };
        }

        var r = this._images.filter(function (x) {
          return x.src === _this2._forceLoad.src;
        });

        var img = r[this._forceLoad.idx];

        if (img) {
          var idx = this._images.findIndex(function (x) {
            return x === img;
          });

          Ember.set(this, 'index', idx);
          this._forceLoad = null;
        }
      }
    }),
    currentImage: Ember.computed('index', '_images.[]', function () {
      return this._images[Ember.get(this, 'index') | 0];
    }),
    _currentImage: Ember.computed('currentImage', function () {
      if (Ember.get(this, 'currentImage')) {
        return [Ember.get(this, 'currentImage')];
      } else {
        return [];
      }
    }),
    hasDescription: Ember.computed('currentImage.description.data', function () {
      return (Ember.get(this, 'currentImage.description.data') || []).some(function (x, i) {
        return i % 2 == 1 ? (x || '').trim().length > 0 : false;
      });
    }),
    _prevImage: function _prevImage(e) {
      e.preventDefault();
      var idx = (Ember.get(this, 'index') | 0) - 1;

      if (idx < 0) {
        idx = this._images.length - 1;
      }

      Ember.set(this, 'index', idx);
    },
    _nextImage: function _nextImage(e) {
      e.preventDefault();
      var idx = (Ember.get(this, 'index') | 0) + 1;
      idx = idx % this._images.length;
      Ember.set(this, 'index', idx);
    },
    _navImage: function _navImage(e) {
      e.preventDefault();
      var p = (e.pageX - e.currentTarget.offsetLeft) / e.currentTarget.offsetWidth;

      if (p > 0.5) {
        this._nextImage(e);
      } else {
        this._prevImage(e);
      }
    },
    _index: Ember.computed('index', function () {
      return (Ember.get(this, 'index') | 0) + 1;
    })
  });

  _exports.default = _default;
});