define("lokaltipp-ember/pods/veranstaltungsraum/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ZXjDqU0",
    "block": "{\"symbols\":[\"raum\"],\"statements\":[[7,\"div\"],[11,\"class\",\"search-wrap\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"items\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"veranstaltungsraum-list-item\",null,[[\"item\",\"noMap\"],[[24,1,[]],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\\n\"],[4,\"top-bar-lokal\",null,[[\"lokal\",\"isLokalPage\",\"forceOpen\"],[[25,[\"model\",\"lokal\"]],false,true]],{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Veranstaltungsräume\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/veranstaltungsraum/list/template.hbs"
    }
  });

  _exports.default = _default;
});