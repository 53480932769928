define("lokaltipp-ember/helpers/format-as-shortdate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAsShortDate = formatAsShortDate;
  _exports.default = void 0;

  function formatAsShortDate(params
  /*, hash*/
  ) {
    if (!params[0]) {
      return null;
    }

    var parts = ((params[0] || '').split('T')[0] || '').split('-');
    return "".concat(parts[2], ".").concat(parts[1], ".");
  }

  var _default = Ember.Helper.helper(formatAsShortDate);

  _exports.default = _default;
});