define("lokaltipp-ember/pods/catch-all/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pDom: Ember.computed(function () {
      return document.body;
    }),
    premium: Ember.computed.gt('model.lokal.premiumStatusId', 0),
    isLokal: Ember.computed('model.type', function () {
      return Ember.get(this, 'model.type') === 'l';
    }),
    isArticle: Ember.computed('model.type', function () {
      return Ember.get(this, 'model.type') === 'a';
    })
  });

  _exports.default = _default;
});