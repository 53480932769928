define("lokaltipp-ember/instance-initializers/override-ui-page-editor", ["exports", "lt-page-editor/instance-initializers/override-ui-page-editor"], function (_exports, _overrideUiPageEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _overrideUiPageEditor.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _overrideUiPageEditor.initialize;
    }
  });
});