define("lokaltipp-ember/pods/components/share-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kjVZ8OC3",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[7,\"button\"],[12,\"class\",[23,\"styleNamespace\"]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"open\"],null]],[9],[7,\"img\"],[11,\"src\",\"/assets/paper-plane.svg\"],[11,\"alt\",\"teilen\"],[9],[10],[0,\"Teilen\"],[10],[0,\"\\n\\n\\n\"],[4,\"if\",[[25,[\"isOpen\"]]],null,{\"statements\":[[4,\"lt-popup\",null,[[\"title\",\"class\",\"close\"],[\"Teilen\",[29,\"concat\",[[25,[\"styleNamespace\"]],\"-popup\"],null],[29,\"action\",[[24,0,[]],\"close\"],null]]],{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"share\",\"facebook\"],null]],[9],[7,\"img\"],[11,\"src\",\"/assets/facebook2-black.svg\"],[11,\"alt\",\"Facebook\"],[9],[10],[7,\"span\"],[9],[0,\"Facebook\"],[10],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"share\",\"twitter\"],null]],[9],[7,\"img\"],[11,\"src\",\"/assets/twitter-black.svg\"],[11,\"alt\",\"Twitter\"],[9],[10],[7,\"span\"],[9],[0,\"Twitter\"],[10],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"share\",\"whatsapp\"],null]],[9],[7,\"img\"],[11,\"src\",\"/assets/whatsapp-black.svg\"],[11,\"alt\",\"WhatsApp\"],[9],[10],[7,\"span\"],[9],[0,\"WhatsApp\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/share-button/template.hbs"
    }
  });

  _exports.default = _default;
});