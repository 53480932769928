define("lokaltipp-ember/pods/components/page-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    pageEditorService: Ember.inject.service(),
    tagName: '',
    pageEditorReferences: Ember.inject.service(),
    _updateReferences: function _updateReferences() {
      var refs = Ember.get(this, 'pageEditorReferences');
      var references = Ember.get(this, 'references');

      if (this._lastReferences == references) {
        // do nothing
        return;
      }

      if (this._lastReferences) {
        refs.removeReferences(this._lastReferences);
        this._lastReferences = null;
      }

      if (references) {
        refs.addReferences(references);
        this._lastReferences = references;
      }
    },
    willRender: function willRender() {
      this._updateReferences();
    },
    didDestroyElement: function didDestroyElement() {
      Ember.set(this, 'references', null);

      this._updateReferences();
    },
    transition: function transition(query, options) {
      var references = Ember.get(this, 'references');
      return Ember.get(this, 'pageEditorService').transition(query, options, references);
    },
    resolver: function resolver(type, query, options) {
      var references = Ember.get(this, 'references');
      return Ember.get(this, 'pageEditorService').resolver(type, query, options, references);
    },
    _value: Ember.computed('model.type', 'value', 'hasAuthorBlock', 'model.author', 'model.publishDate', 'hasVrTeaserBlock', function () {
      var blocks = Ember.get(this, 'value') || [];

      switch (Ember.get(this, 'model.type')) {
        case 'a':
          /* DOES NOTHING, used to add Author Block */
          break;

        case 'r':
          if (!Ember.get(this, 'hasVrTeaserBlock')) {
            blocks = [{
              component: 'vrTeaser',
              width: -1
            }].concat(_toConsumableArray(blocks));
          }

          break;
      }

      return blocks;
    }),
    hasVrTeaserBlock: Ember.computed('value', function () {
      var markup = Ember.get(this, 'value') || [];
      return findBlock('vrTeaser', markup) ? true : false;
    })
  });

  _exports.default = _default;

  function findBlock(name, blocks) {
    var _iterator = _createForOfIteratorHelper(blocks),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var x = _step.value;

        if (x.component === 'inception') {
          var tmp = findBlock(name, x.data || []);

          if (tmp) {
            return tmp;
          }
        }

        if (x.component === 'pkBlock') {
          if (!x.data || !x.data.inception || !x.data.inception.data) {
            continue;
          }

          var _tmp = findBlock(name, x.data.inception.data || []);

          if (_tmp) {
            return _tmp;
          }
        } else if (x.component === name) {
          // found the author block
          return true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
});