define("lokaltipp-ember/pods/components/share-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nativeShare: Ember.computed(function () {
      return 'canShare' in navigator && navigator.canShare();
    }),
    isArticle: Ember.computed('model.article', function () {
      return 'article' in Ember.get(this, 'model');
    }),
    isLokal: Ember.computed('model.lokal', function () {
      return 'lokal' in Ember.get(this, 'model');
    }),
    isOpen: false,
    actions: {
      open: function open() {
        var _this = this;

        if (Ember.get(this, 'nativeShare')) {
          navigator.share({
            url: location.href
          }).catch(function () {
            Ember.set(_this, 'isOpen', true);
          });
        } else {
          Ember.set(this, 'isOpen', true);
        }
      },
      close: function close() {
        Ember.set(this, 'isOpen', false);
      },
      share: function share(what) {
        var url;

        switch (what) {
          case 'twitter':
            url = 'https://twitter.com/share?' + new URLSearchParams({
              url: location.href,
              related: 'lokaltipp.at'
            });
            break;

          case 'facebook':
            url = 'https://www.facebook.com/sharer/sharer.php?' + new URLSearchParams({
              u: location.href
            });
            break;

          case 'whatsapp':
            url = 'https://wa.me/?' + new URLSearchParams({
              text: document.title.replace(' - Lokaltipp.at', '') + '\r\n' + location.href
            });
            break;
        }

        if (url) {
          window.open(url, '_blank', 'noopener');
        }
      }
    }
  });

  _exports.default = _default;
});