define("lokaltipp-ember/pods/error/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    statusCode: Ember.computed.readOnly('model.status'),
    statusCode404: Ember.computed.equal('status', 404)
  });

  _exports.default = _default;
});