define("lokaltipp-ember/services/page-editor-references", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this._references = [];
    },
    references: Ember.computed.readOnly('_references'),
    addReferences: function addReferences(refs) {
      this._references.pushObjects(refs);
    },
    removeReferences: function removeReferences(refs) {
      this._references.removeObjects(refs);
    }
  });

  _exports.default = _default;
});