define("lokaltipp-ember/pods/components/cookie-banner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cookieName = 'lokaltipp-cookie-banner';

  var _default = Ember.Component.extend({
    tagName: '',
    bodyDom: Ember.computed(function () {
      return document.body;
    }),
    acceptDate: null,
    visible: Ember.computed('acceptDate', function () {
      try {
        return !localStorage.getItem(cookieName);
      } catch (e) {
        /* IGNORE */
        return true;
      }
    }),
    actions: {
      accept: function accept() {
        var obj = {
          acceptDate: new Date()
        };

        try {
          localStorage.setItem(cookieName, JSON.stringify(obj));
        } catch (e) {
          /* IGNORE */
        }

        Ember.set(this, 'acceptDate', obj.acceptDate);
      }
    }
  });

  _exports.default = _default;
});