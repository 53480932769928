define("lokaltipp-ember/pods/components/top-bar-startseite/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    topBarElement: Ember.computed(function () {
      return document.getElementById('top-bar-element');
    }),
    currentDate: Ember.computed(function () {
      return new Date().toLocaleString('de-AT', {
        hourCycle: 'h24',
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit'
      });
    }),
    modifiedRelative: Ember.computed('lastModified', function () {
      var lastModified = Ember.get(this, 'lastModified');
      return getRelativeTimeFrom(lastModified);
    }),
    lastModified: Ember.computed('model.lastModified', function () {
      var lastModifiedString = Ember.get(this, 'model.lastModified');

      if (lastModifiedString) {
        return new Date(lastModifiedString);
      }

      return new Date();
    })
  }); // stolen from https://github.com/moment/moment/blob/2e2a5b35439665d4b0200143d808a7c26d6cd30f/src/lib/duration/humanize.js


  _exports.default = _default;
  var thresholds = {
    s: 45,
    // seconds to minute
    m: 45,
    // minutes to hour
    h: 22,
    // hours to day
    d: 26,
    // days to month
    M: 11 // months to year

  };

  function getRelativeTimeFrom(date) {
    var now = new Date();
    var diff = now - date;
    var seconds = Math.round(diff / 1000);
    var minutes = Math.round(seconds / 60);
    var hours = Math.round(minutes / 60);
    var days = Math.round(hours / 24);
    var months = Math.round(days / (365 / 12));
    var years = Math.round(months / 12);
    var a = seconds < thresholds.s && ['Sekunde', 'n', seconds] || minutes < thresholds.m && ['Minute', 'n', minutes] || hours < thresholds.h && ['Stunde', 'n', hours] || days < thresholds.d && ['Tag', 'en', days] || months < thresholds.M && ['Monat', 'en', months] || ['Jahr', 'en', years];
    var value = a[2];
    var name = a[0];
    var endName = name + (value > 1 ? a[1] : ''); // mehrzahl name

    return "vor ".concat(value, " ").concat(endName);
  }
});