define("lokaltipp-ember/pods/calendar/lokal/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var current = new Date();
      var asUtc = new Date(Date.UTC(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0, 0));
      this.transitionTo('calendar.day.lokal', asUtc.toISOString().substring(0, 10).replace(/-/g, ''), params.lokal_id);
    }
  });

  _exports.default = _default;
});