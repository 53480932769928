define("lokaltipp-ember/helpers/format-as-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAsDate = formatAsDate;
  _exports.default = void 0;

  function formatAsDate(params
  /*, hash*/
  ) {
    if (!params[0]) {
      return null;
    }

    var r = [];
    params.filter(function (x) {
      return x;
    }).map(function (x) {
      var parts = ((x || '').split('T')[0] || '').split('-');
      return "".concat(parts[2], ".").concat(parts[1], ".").concat(parts[0]);
    }).forEach(function (x) {
      if (!r.includes(x)) {
        r.push(x);
      }
    });
    return r.join(' - ');
  }

  var _default = Ember.Helper.helper(formatAsDate);

  _exports.default = _default;
});