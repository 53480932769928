define("lokaltipp-ember/pods/components/advent-calendar-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageEditorReferences: Ember.inject.service(),
    pageEditorService: Ember.inject.service(),
    tagName: '',
    advent: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._adventEvent = this._adventEvent.bind(this);
      var v = Ember.get(this, 'advent');

      if (v) {
        Ember.set(this, 'showPopup', true); // load ?
      }
    },
    bodyDom: Ember.computed(function () {
      return document.body;
    }),
    willInsertElement: function willInsertElement() {
      document.body.addEventListener('lt-page-editor-sp-advent-calendar', this._adventEvent);
    },
    willDestroyElement: function willDestroyElement() {
      document.body.removeEventListener('lt-page-editor-sp-advent-calendar', this._adventEvent);
    },
    _adventEvent: function _adventEvent(data) {
      Ember.set(this, 'advent', data.detail.day.gruppe_id);
    },
    content: Ember.computed('advent', 'pageEditorReferences.references.@each.id', function () {
      var advent = Ember.get(this, 'advent');
      var references = Ember.get(this, 'pageEditorReferences.references');

      if (!advent) {
        return null;
      }

      var key = 'g' + advent;
      return (references.find(function (x) {
        return Ember.get(x, 'id') == key;
      }) || {}).data;
    }),
    markup: Ember.computed('advent', 'content.markup', function () {
      //const markup = get(this, 'content.markup');
      var advent = Ember.get(this, 'advent');
      var key = 'g' + advent;
      return [{
        width: -1,
        component: 'spGewinnspiel',
        data: {
          link: {
            data: key
          }
        }
      }];
    }),
    obsAdvent: Ember.observer('advent', function () {
      if (Ember.get(this, 'showPopup')) {
        if (!Ember.get(this, 'advent')) {
          Ember.set(this, 'showPopup', false);
        }
      } else {
        if (Ember.get(this, 'advent')) {
          Ember.set(this, 'showPopup', true);
        }
      }

      if (Ember.get(this, 'showPopup')) {
        // load stuff:
        var v = Ember.get(this, 'advent');

        if (v) {
          Ember.set(this, 'showPopup', true); // loader
        }
      }
    })
  });

  _exports.default = _default;
});