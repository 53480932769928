define("lokaltipp-ember/pods/calendar/event/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/b2q/Os",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"event-popup\",null,[[\"event\",\"inplaceHack\"],[[25,[\"model\"]],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/calendar/event/template.hbs"
    }
  });

  _exports.default = _default;
});