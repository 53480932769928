define("lokaltipp-ember/pods/components/search-result-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tLWwHg1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"model\",\"result\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"info-bar\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"caption\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,\"caption\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,[\"model\",\"result\",\"length\"]],false],[0,\" Ergebnisse für \"],[7,\"span\"],[9],[1,[25,[\"model\",\"term\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"search-wrap-global\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"result\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"search-result\",null,[[\"model\"],[[25,[\"model\",\"result\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"no-results\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"Keine Ergebnisse gefunden\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[25,[\"caption\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\"],[9],[0,\"Weitere Premium Lokaltipps...\"],[10],[0,\"\\n    \"],[1,[23,\"random-premium\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/search-result-list/template.hbs"
    }
  });

  _exports.default = _default;
});