define("lokaltipp-ember/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7Bt3oUy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"errorPage\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"statusCode404\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h1\"],[9],[0,\"404\"],[10],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"Die gewünschte Seite konnte nicht gefunden werden\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h1\"],[9],[0,\"Unbekannter Fehler\"],[10],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"Die gewünschte Seite konnte nicht angezeigt werden\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});