define("lokaltipp-ember/pods/catch-all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pw+fCZEV",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-editor\",null,[[\"tagName\",\"value\",\"model\",\"references\"],[\"article\",[25,[\"model\",\"markup\"]],[29,\"hash\",null,[[\"author\",\"publishDate\",\"category\",\"lokal\",\"lastUpdate\",\"type\"],[[25,[\"model\",\"author\"]],[25,[\"model\",\"publishDate\"]],[25,[\"model\",\"category\"]],[25,[\"model\",\"lokal\"]],[25,[\"model\",\"lastUpdate\"]],[25,[\"model\",\"type\"]]]]],[25,[\"model\",\"references\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"isLokal\"]]],null,{\"statements\":[[4,\"unless\",[[25,[\"premium\"]]],null,{\"statements\":[[0,\"    \"],[7,\"section\"],[11,\"data-nosnippet\",\"\"],[11,\"class\",\"search-wrap-global hide-for-google\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"Weitere Premium Lokaltipps...\"],[10],[0,\"\\n      \"],[1,[29,\"random-premium\",null,[[\"publicId\"],[[25,[\"model\",\"lokal\",\"publicId\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[29,\"top-bar-lokal\",null,[[\"lokal\",\"isLokalPage\"],[[25,[\"model\",\"lokal\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isArticle\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"top-bar-article\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[25,[\"search\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"data-nosnippet\",\"\"],[11,\"class\",\"search-wrap-global hide-for-google\"],[9],[0,\"\\n    \"],[1,[29,\"search-result\",null,[[\"model\"],[[25,[\"search\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/catch-all/template.hbs"
    }
  });

  _exports.default = _default;
});