define("lokaltipp-ember/pods/components/lt-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pey8pJgV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"inplaceHack\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"styleNamespace\"],\" \",[23,\"class\"],\" inplace-hack\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"window\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"content \",[23,\"contentClass\"]]]],[9],[0,\"\\n        \"],[15,1,[true]],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"button-wrap \",[23,\"buttonWrapClass\"]]]],[9],[0,\"\\n        \"],[15,1,[false]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[25,[\"bodyDom\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"dialog\"],[12,\"id\",[23,\"guid\"]],[12,\"class\",[30,[[23,\"styleNamespace\"],\" \",[23,\"class\"]]]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"backdrop\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"window\"],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[30,[\"title-bar \",[23,\"titleBarClass\"]]]],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[30,[\"title \",[23,\"titleClass\"]]]],[9],[0,\"\\n            \"],[1,[23,\"title\"],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[23,\"close\"]],[9],[7,\"img\"],[11,\"alt\",\"Schließen\"],[11,\"src\",\"/assets/menu-close.svg\"],[9],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[30,[\"content \",[23,\"contentClass\"]]]],[9],[0,\"\\n          \"],[15,1,[true]],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[30,[\"button-wrap \",[23,\"buttonWrapClass\"]]]],[9],[0,\"\\n          \"],[15,1,[false]],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/lt-popup/template.hbs"
    }
  });

  _exports.default = _default;
});