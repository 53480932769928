define("lokaltipp-ember/pods/newsletter/unsubscribe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "APmG3k69",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Erfolgreich abgemeldet\"],[10],[0,\"\\nSie wurden erfolgreich vom Newsletter abgemeldet\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/newsletter/unsubscribe/template.hbs"
    }
  });

  _exports.default = _default;
});