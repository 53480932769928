define("lokaltipp-ember/utils/update-seo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = update_seo;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function removeElement(selector) {
    var el = document.querySelectorAll(selector);

    if (!el || !el.length) {
      return;
    }

    _toConsumableArray(el).forEach(function (x) {
      x.parentElement.removeChild(x);
    });
  }

  function update_seo(seo_data) {
    // remove all
    removeElement('meta[name="description"]');
    removeElement('script[type="application/ld+json"]');
    removeElement('meta[property^="og:"]');
    removeElement('link[rel="canonical"]');
    removeElement('noscript');

    if (!seo_data) {
      return;
    }

    var canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', seo_data.canonical);
    document.head.appendChild(canonical);

    if (seo_data.ldJson) {
      var tmp = document.createElement('div');
      tmp.innerHTML = seo_data.ldJson;
      tmp.querySelectorAll('*').forEach(function (x) {
        document.head.appendChild(x);
      });
    }

    if (seo_data.ldJsonCarousel) {
      var _tmp = document.createElement('div');

      _tmp.innerHTML = seo_data.ldJsonCarousel;

      _tmp.querySelectorAll('*').forEach(function (x) {
        document.head.appendChild(x);
      });
    }

    if (seo_data.og) {
      var _tmp2 = document.createElement('div');

      _tmp2.innerHTML = seo_data.og;

      _tmp2.querySelectorAll('*').forEach(function (x) {
        document.head.appendChild(x);
      });
    }

    var description = document.querySelector('meta[property="og:description"]');

    if (description) {
      var _tmp3 = document.createElement('meta');

      _tmp3.setAttribute('name', 'description');

      _tmp3.setAttribute('content', description.getAttribute('content'));

      document.head.appendChild(_tmp3);
    }
  }
});