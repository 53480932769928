define("lokaltipp-ember/pods/components/cookie-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xUY+9XdJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"visible\"]]],null,{\"statements\":[[4,\"in-element\",[[25,[\"bodyDom\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[23,\"styleNamespace\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"banner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n          \"],[15,1],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"accept\"],null]],[9],[0,\"Akzeptieren\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/cookie-banner/template.hbs"
    }
  });

  _exports.default = _default;
});