define("lokaltipp-ember/pods/components/lt-attributes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "le2rkN2V",
    "block": "{\"symbols\":[\"group\",\"item\"],\"statements\":[[4,\"each\",[[25,[\"groupedItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"group\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[24,1,[\"type\"]],false],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[\"items\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[7,\"label\"],[9],[7,\"input\"],[12,\"value\",[24,2,[\"id\"]]],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"changeCheck\"],null]],[12,\"checked\",[24,2,[\"checked\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\" \"],[1,[24,2,[\"value\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/lt-attributes/template.hbs"
    }
  });

  _exports.default = _default;
});