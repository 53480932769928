define("lokaltipp-ember/utils/url-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = urlSafe;

  function urlSafe(input) {
    if (!input) {
      return null;
    }

    input = "".concat(input).toLowerCase();

    if (input.normalize) {
      input = input.normalize('NFKD');
      input = input.replace(/[\u0300-\u036f]/g, '');
    }

    input = input.replace(/-/g, '-').replace(/_/g, '-').replace(/[^a-z0-9 ]/ig, '').trim().replace(/ +/g, '-');
    return input;
  }
});