define("lokaltipp-ember/pods/components/search-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loading: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if ('requestIdleCallback' in window) {
        requestIdleCallback(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          Ember.set(_this, 'loading', false);
        });
      } else {
        setTimeout(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          Ember.set(_this, 'loading', false);
        }, 100);
      }
    },
    modelWithComponent: Ember.computed('model', function () {
      var model = Ember.get(this, 'model') || [];
      return model.map(function (item) {
        var tmp = Ember.ObjectProxy.create({
          guid: Ember.guidFor(item),
          content: item
        });

        switch (item.type) {
          case 'Article':
            tmp.component = 'search-result-article';
            break;

          case 'Lokal':
            tmp.component = 'search-result-lokal';
            break;

          case 'Veranstaltungsraum':
            tmp.component = 'veranstaltungsraum-list-item';
            break;
        }

        return tmp;
      });
    })
  });

  _exports.default = _default;
});