define("lokaltipp-ember/pods/components/reset-with/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    _value: Ember.computed('value', function () {
      return [this.value];
    })
  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;
});