define("lokaltipp-ember/helpers/format-as-weekday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAsWeekday = formatAsWeekday;
  _exports.default = void 0;
  var WEEKDAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

  function formatAsWeekday(params
  /*, hash*/
  ) {
    if (!params[0]) {
      return null;
    }

    var date = null;

    try {
      date = new Date(params[0]);
    } catch (ex) {
      return null;
    }

    return WEEKDAYS[(date.getDay() + 6) % 7];
  }

  var _default = Ember.Helper.helper(formatAsWeekday);

  _exports.default = _default;
});