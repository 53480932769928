define("lokaltipp-ember/pods/components/search-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jsfa4KOm",
    "block": "{\"symbols\":[\"item\",\"idx\"],\"statements\":[[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"modelWithComponent\"]]],[[\"index\"],[\"guid\"]],{\"statements\":[[4,\"if\",[[24,1,[\"searchScore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"search-score\"],[9],[0,\"Score \"],[1,[24,1,[\"searchScore\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"lt-page-editor-delay-render\",null,[[\"idx\"],[[24,2,[]]]],{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[24,1,[\"component\"]]],[[\"item\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/search-result/template.hbs"
    }
  });

  _exports.default = _default;
});