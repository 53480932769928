define("lokaltipp-ember/pods/calendar/event/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var event_id = _ref.event_id;
      return event_id;
    }
  });

  _exports.default = _default;
});