define("lokaltipp-ember/router", ["exports", "lokaltipp-ember/config/environment", "lokaltipp-ember/utils/check-index", "lokaltipp-ember/utils/update-seo"], function (_exports, _environment, _checkIndex, _updateSeo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var scrollData = new Map();
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeWillChange', function () {
        return _this._willTransition.apply(_this, arguments);
      });
      this.on('routeDidChange', function () {
        return _this._didTransition.apply(_this, arguments);
      });
      /*
      document.body.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
       }, true);
      */

      document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'visible') {
          // copy session into localstorage
          for (var _i = 0, _Object$keys = Object.keys(window.sessionStorage); _i < _Object$keys.length; _i++) {
            var k = _Object$keys[_i];
            window.localStorage.setItem('session_' + k, window.sessionStorage.getItem(k));
          }
        }
      });
      window.addEventListener('focus', function () {
        if (document.visibilityState === 'visible') {
          // copy session into localstorage
          for (var _i2 = 0, _Object$keys2 = Object.keys(window.sessionStorage); _i2 < _Object$keys2.length; _i2++) {
            var k = _Object$keys2[_i2];
            window.localStorage.setItem('session_' + k, window.sessionStorage.getItem(k));
          }
        }
      });
      window.addEventListener('unload', function () {
        try {
          for (var _i3 = 0, _Object$keys3 = Object.keys(window.localStorage); _i3 < _Object$keys3.length; _i3++) {
            var k = _Object$keys3[_i3];

            if (k.startsWith('session_')) {
              window.localStorage.removeItem(k);
            }
          }
        } catch (e) {
          /* IGNORE */
        }
      });
    },
    _updateScroll: function _updateScroll() {
      if (!this._workOnDidTransition) {
        return;
      }

      this._workOnDidTransition = false;

      if (window.skipScrollTop) {
        window.skipScrollTop = false;
        return;
      }

      this._lastTransition = window.history.state.uuid;
      var el = document.scrollingElement;

      if (el) {
        var sc = scrollData['get'](window.history.state.uuid) || {};

        try {
          el.scrollTo(sc.left, sc.top);
        } catch (e) {
          //IE 11 ?
          el.scrollLeft = sc.left;
          el.scrollTop = sc.top;
        }

        scrollData.delete(window.history.state.uuid);
      } // check index


      (0, _checkIndex.default)();
    },
    _getSession: function _getSession(key) {
      try {
        return JSON.parse(window.sessionStorage.getItem(key));
      } catch (e) {
        try {
          if (JSON.parse(this['session_' + key])) {
            return JSON.parse(this['session_' + key]);
          } else {
            return JSON.parse(window.localStorage.getItem('session_' + key));
          }
        } catch (e) {
          return null;
        }
      }
    },
    _setSession: function _setSession(key, value) {
      try {
        window.localStorage.setItem('session_' + key, JSON.stringify(value));
      } catch (e) {
        /* IGNORE */
      }

      try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        this['session_' + key] = JSON.stringify(value);
      }

      return value;
    },
    _didTransition: function _didTransition() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var trs = arguments.length <= 0 ? undefined : arguments[0];
      var id = (this._getSession('last_id') || 0) + 1;

      var from = this._getSession('last');

      var to = null;

      var from_uuid = this._getSession('last_uuid');

      var to_uuid = null;
      var session = this._getSession('session') || window.history.state.uuid;

      this._setSession('session', session);

      try {
        to = this.generate.apply(this, [trs.to.name].concat(_toConsumableArray(trs.to.paramNames.map(function (x) {
          return trs.to.params[x];
        }))));
      } catch (e) {
        /* IGNORE */
      }

      try {
        to_uuid = window.history.state.uuid;
      } catch (e) {
        /* IGNORE */
      }

      fetch('/api/flow', {
        method: 'POST',
        body: JSON.stringify({
          id: id,
          session: session,
          from: from,
          to: to,
          from_uuid: from_uuid,
          to_uuid: to_uuid,
          referrer: document.referrer
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      this._setSession('last', to);

      this._setSession('last_uuid', to_uuid);

      this._setSession('last_id', id);

      Ember.run.schedule('afterRender', function () {
        _this2._updateScroll();
      });
      Ember.run.next(function () {
        _this2._updateScroll();
      });
    },
    _willTransition: function _willTransition(transition) {
      if (transition.from && transition.to) {
        window.skipScrollTop = transition.from.name === transition.to.name && transition.from.params === transition.to.params;
      }

      (0, _updateSeo.default)(null);
      this._workOnDidTransition = true;

      this._super.apply(this, arguments);

      var el = document.scrollingElement;

      if (el && this._lastTransition) {
        try {
          if (!scrollData.has(this._lastTransition)) {
            scrollData['set'](this._lastTransition, {
              top: el.scrollTop,
              left: el.scrollLeft
            });
          }
        } catch (e) {//ignore
        }
      }
    }
  });
  Router.map(function () {
    this.route('index', {
      path: ''
    });
    this.route('archive', {
      path: 'archiv'
    });
    this.route('catch-all', {
      path: '*url'
    });
    this.route('search');
    this.route('veranstaltungsraum', {
      path: 'veranstaltungsraum'
    }, function () {
      this.route('list', {
        path: 'lokal/:lokal_id'
      });
      this.route('raum', {
        path: 'raum/:raum_id'
      });
    });
    this.route('calendar', {
      path: 'kalender'
    }, function () {
      this.route('day', {
        path: ':day'
      }, function () {
        this.route('lokal', {
          path: 'lokal/:lokal_id'
        });
        this.route('index', {
          path: ''
        });
      });
      this.route('index', {
        path: ''
      });
      this.route('lokal', {
        path: 'lokal/:lokal_id'
      });
      this.route('event', {
        path: 'event/:event_id'
      });
    });
    this.route('newsletter', function () {
      this.route('subscribe', {
        path: ''
      });
      this.route('unsubscribe', {
        path: 'abmelden/:id'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});