define("lokaltipp-ember/app", ["exports", "lokaltipp-ember/resolver", "ember-load-initializers", "lokaltipp-ember/config/environment", "@sentry/browser"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_environment.default.environment == 'production') {
    Sentry.init({
      dsn: 'https://266387bdb71e4bb7aa4695d6eb69f9a0@sentry.unidata.at/7',
      release: _environment.default.git
    });
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});