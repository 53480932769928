define("lokaltipp-ember/pods/components/lt-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    bodyDom: Ember.computed(function () {
      return document.body;
    }),
    guid: Ember.computed(function () {
      return Ember.guidFor(this);
    }),
    didInsertElement: function didInsertElement() {
      var dialog = document.getElementById(this.guid);

      if (dialog && 'showModal' in dialog) {
        dialog.showModal();
      }

      document.body.style.overflow = 'hidden';
    },
    willDestroyElement: function willDestroyElement() {
      var dialog = document.getElementById(this.guid);

      if (dialog && 'close' in dialog) {
        dialog.close();
      }

      document.body.style.overflow = '';
    }
  });

  _exports.default = _default;
});