define("lokaltipp-ember/pods/application/controller", ["exports", "lokaltipp-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      img: {
        replace: true
      },
      advent: {
        replace: true
      },
      event: {
        replace: true
      }
    },
    img: null,
    advent: null,
    event: null,
    testing: Ember.computed(function () {
      return _environment.default.environment !== 'production' || window.location.href.replace('http://', '').replace('https://', '').startsWith('testing');
    }),
    development: Ember.computed(function () {
      return _environment.default.environment !== 'production';
    }),
    bodyDom: Ember.computed(function () {
      return document.body;
    })
  });

  _exports.default = _default;
});