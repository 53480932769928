define("lokaltipp-ember/pods/veranstaltungsraum/raum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W+M162Es",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-editor\",null,[[\"value\",\"model\",\"references\"],[[25,[\"model\",\"markup\"]],[29,\"hash\",null,[[\"author\",\"publishDate\",\"category\",\"lokal\",\"veranstaltungsraum\",\"lastUpdate\",\"type\"],[[25,[\"model\",\"author\"]],[25,[\"model\",\"publishDate\"]],[25,[\"model\",\"category\"]],[25,[\"model\",\"lokal\"]],[25,[\"model\",\"veranstaltungsraum\"]],[25,[\"model\",\"lastUpdate\"]],[25,[\"model\",\"type\"]]]]],[25,[\"model\",\"references\"]]]]],false],[0,\"\\n\\n\"],[4,\"top-bar-lokal\",null,[[\"lokal\"],[[25,[\"model\",\"lokal\"]]]],{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"veranstaltungsraum.list\",[25,[\"model\",\"lokal\",\"publicId\"]]]],{\"statements\":[[0,\"Veranstaltungsräume\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\"],[9],[1,[25,[\"model\",\"veranstaltungsraum\",\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/veranstaltungsraum/raum/template.hbs"
    }
  });

  _exports.default = _default;
});