define("lokaltipp-ember/pods/components/calendar-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isSelected:open', 'isTop:top', 'isAnySelected:opaque'],
    isAnySelected: Ember.computed('selected', function () {
      return !!Ember.get(this, 'selected');
    }),
    isSelected: Ember.computed('selected', 'item.publicId', function () {
      var selected = Ember.get(this, 'selected');
      var id = Ember.get(this, 'item.publicId');
      return selected === id;
    }),
    isTop: Ember.computed('item.top', function () {
      return Ember.get(this, 'item.top');
    }),
    showTo: Ember.computed('item.dateFrom', 'item.dateTo', function () {
      return this.dateFrom != this.dateTo;
    }),
    parent: Ember.computed(function () {
      var tmp = document.createElement('div');
      return tmp;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.scrollIntoView = this.scrollIntoView.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.scrollIntoView();
      Ember.run.scheduleOnce('afterRender', this.scrollIntoView);
    },
    scrollIntoView: function scrollIntoView() {
      var selected = Ember.get(this, 'selected');

      if (selected) {
        var modelId = Ember.get(this, 'item.publicId');

        if (selected === modelId) {
          var header = document.querySelector('.x-header');
          var boundHeader = header.getBoundingClientRect();
          var boundEl = this.element.getBoundingClientRect();

          if (boundEl.top < boundHeader.height || window.innerHeight < boundEl.top + boundEl.height) {
            var scrollableElement = document.scrollingElement;
            scrollableElement.scrollTop = scrollableElement.scrollTop + boundEl.top - boundHeader.height - 20;
          }
        }
      }
    }
  });

  _exports.default = _default;
});