define("lokaltipp-ember/pods/components/top-bar-article/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BuhDmewH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"in-element\",[[25,[\"topBarElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"styleNamespace\"],\" top-bar-article\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"left\"],[9],[0,\"\\n      \"],[1,[25,[\"model\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-spacer\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"publishDate\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"format-as-date\",[[25,[\"model\",\"publishDate\"]]],null],false]],\"parameters\":[]},null],[4,\"if\",[[25,[\"model\",\"author\"]]],null,{\"statements\":[[0,\",\\n          \"],[1,[25,[\"model\",\"author\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[1,[29,\"share-button\",null,[[\"model\"],[[29,\"hash\",null,[[\"article\"],[[25,[\"model\"]]]]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/components/top-bar-article/template.hbs"
    }
  });

  _exports.default = _default;
});