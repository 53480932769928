define("lokaltipp-ember/pods/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fF/zw10P",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-editor\",null,[[\"tagName\",\"value\",\"references\",\"model\"],[\"article\",[25,[\"model\",\"markup\"]],[25,[\"model\",\"references\"]],[29,\"hash\",null,[[\"lastModified\"],[[25,[\"model\",\"lastModified\"]]]]]]]],false],[0,\"\\n\"],[1,[29,\"top-bar-startseite\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/index/template.hbs"
    }
  });

  _exports.default = _default;
});