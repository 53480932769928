define("lokaltipp-ember/pods/archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XTJ1rl6Y",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"search-result-list\",null,[[\"caption\",\"model\"],[\"Archiv\",[29,\"hash\",null,[[\"result\"],[[25,[\"model\"]]]]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/archive/template.hbs"
    }
  });

  _exports.default = _default;
});