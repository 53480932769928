define("lokaltipp-ember/pods/components/lt-calendar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    pageEditorService: Ember.inject.service(),
    date: Ember.computed('day', function () {
      var day = "".concat(this.day.substr(0, 4), "-").concat(this.day.substr(4, 2), "-").concat(this.day.substr(6, 2));
      return day;
    }),
    today: Ember.computed('date', function () {
      var d1 = new Date(this.date + 'T00:00:00.000Z');
      var d2 = new Date();
      var check = d1.toISOString().substring(0, 10).replace(/-/g, '');
      var today = d2.toISOString().substring(0, 10).replace(/-/g, '');
      return today != check ? today : null;
    }),
    prevMonthDate: Ember.computed('date', function () {
      var d = new Date(this.date + 'T00:00:00.000Z');
      d.setUTCDate(1);
      d.setUTCMonth(d.getUTCMonth() - 1);
      return d.toISOString().substring(0, 10).replace(/-/g, '');
    }),
    nextMonthDate: Ember.computed('date', function () {
      var d = new Date(this.date + 'T00:00:00.000Z');
      d.setUTCDate(1);
      d.setUTCMonth(d.getUTCMonth() + 1);
      return d.toISOString().substring(0, 10).replace(/-/g, '');
    }),
    topEvents: Ember.computed('events', function () {
      var topEvents = this.events.filter(function (x) {
        return x.top;
      });
      var dates = new Set();
      topEvents.forEach(function (x) {
        return dates.add(x.date);
      });

      var dates2 = _toConsumableArray(dates);

      dates2.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      return dates2.map(function (x) {
        return {
          date: x,
          events: topEvents.filter(function (y) {
            return y.date == x;
          })
        };
      });
    }),
    topResolver: function topResolver(event) {
      var _this$pageEditorServi;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (args[0] === 'calendarTop') {
        return event;
      }

      return (_this$pageEditorServi = this.pageEditorService).resolver.apply(_this$pageEditorServi, args);
    },
    obsDay: Ember.observer('day', function () {
      this.scrollToDate = true;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.scrollToDate = true;
    },
    didRender: function didRender() {
      if (this.scrollToDate) {
        window.skipScrollTop = true;
        this.scrollToDate = false;
        var d = new Date(this.date + 'T00:00:00.000Z');
        var dISO = d.toISOString();
        var pos = document.scrollingElement.scrollTop;
        var els = document.querySelectorAll("[data-date^=\"".concat(dISO.substring(0, 10), "\"]"));
        els.forEach(function (x) {
          return x.scrollIntoView();
        });
        document.scrollingElement.scrollTop = pos;

        for (var i = 0; i < 32; ++i) {
          var dISO2 = d.toISOString();
          var el = document.querySelector("h2[data-date^=\"".concat(dISO2.substring(0, 10), "\"]"));

          if (el) {
            el.scrollIntoView();
            document.scrollingElement.scrollTop -= 150;
            break;
          }

          d.setUTCDate(d.getUTCDate() + 1);
        }
      }
    }
  });

  _exports.default = _default;
});