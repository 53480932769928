define("lokaltipp-ember/pods/search-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0JK0Hkkp",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"loading-spinner\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lokaltipp-ember/pods/search-loading/template.hbs"
    }
  });

  _exports.default = _default;
});